import Link from 'next/link';

import LogoLgIcon from '@/shared/components/icons/logo/logo-lg';

export const Logo = () => {
  return (
    <Link aria-label="Перейти на главную" href="/" prefetch={false}>
      <LogoLgIcon className="h-6 w-auto text-black dark:text-white sm:h-5 md:h-7" />
    </Link>
  );
};
