import * as React from 'react';

const IndividualLessonsIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4s-4 1.79-4 4s1.79 4 4 4m0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4"
      ></path>
    </svg>
  );
};

const MemoIndividualLessonsIcon = React.memo(IndividualLessonsIcon);
export default MemoIndividualLessonsIcon;
