import * as React from 'react';
import { useEffect, useState } from 'react';

import { Footer } from '@/widgets/layouts/main/components/footer';
import { Header } from '@/widgets/layouts/main/components/header';

import { MarketingPopup } from './components/marketing-popup';

export interface MainLayoutProps {
  children?: React.ReactNode;
}

export const MainLayout = (props: MainLayoutProps): JSX.Element => {
  const { children } = props;

  const [isMarketingPopupVisible, setMarketingPopupVisibility] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      const hasUserSeenMarketingPopup = localStorage.getItem('hasUserSeenMarketingTestPopup');

      setMarketingPopupVisibility(hasUserSeenMarketingPopup !== 'true');
    }
  }, []);

  const onPopupClose = () => {
    setMarketingPopupVisibility(false);
    localStorage.setItem('hasUserSeenMarketingTestPopup', 'true');
  };

  return (
    <>
      <Header />

      {children}

      {isMarketingPopupVisible && <MarketingPopup onCloseClick={onPopupClose} />}

      <Footer />
    </>
  );
};
