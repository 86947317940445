import React, { useEffect, useState } from 'react';
import { useTheme } from 'next-themes';
import { RiComputerLine, RiMoonLine, RiSunLine } from 'react-icons/ri';

import { Button } from '@/shared/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/shared/components/ui/dropdown-menu';
import { Label } from '@/shared/components/ui/label';
import { Switch } from '@/shared/components/ui/switch';

const themeIcons: Record<string, React.ComponentType> = {
  light: RiSunLine,
  dark: RiMoonLine,
  system: RiComputerLine,
};

export const ThemeToggle = () => {
  const { theme = 'light', setTheme } = useTheme();

  const ThemeIcon = themeIcons[theme];

  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    if (theme === 'dark' || theme === 'system') {
      setChecked(true);

      return;
    }

    setChecked(false);
  }, [setChecked, theme]);

  const onCheckedChange = () => {
    if (theme === 'dark' || theme === 'system') {
      setTheme('light');

      return;
    }

    setTheme('dark');
  };

  return (
    <>
      <div className="hidden sm:block">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" size="icon-lg">
              <span className="sr-only">Change theme</span>
              <ThemeIcon />
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => setTheme('light')}>Светлая тема</DropdownMenuItem>
            <DropdownMenuItem onClick={() => setTheme('dark')}>Темная тема</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className="sm:hidden">
        <div className="flex items-center space-x-2">
          <Switch id="airplane-mode" checked={isChecked} onCheckedChange={() => onCheckedChange()} />
          <Label htmlFor="airplane-mode">Темная тема</Label>
        </div>
      </div>
    </>
  );
};
