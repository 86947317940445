import dynamic from 'next/dynamic';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import GroupLessonsIcon from '@/shared/components/icons/group-lessons-icon';
import IndividualLessonsIcon from '@/shared/components/icons/individual-lessons-icon';
import PairLessonsIcon from '@/shared/components/icons/pair-lessons-icon';
import SpeakingClubIcon from '@/shared/components/icons/speaking-club-icon';
import { Logo } from '@/shared/components/logo';
import { ThemeToggle } from '@/shared/components/theme-toggle';
import { Button } from '@/shared/components/ui/button';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/shared/components/ui/navigation-menu';
import { cn } from '@/shared/lib/cn';

export const Header = () => {
  const Sidebar = dynamic(() =>
    import('@/widgets/layouts/main/components/sidebar').then((components) => components.Sidebar),
  );

  const pathname = usePathname();

  return (
    <header className="fixed top-0 z-50 w-full border-b border-border/40 bg-background/95 py-4 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container">
        <div className="flex items-center justify-between">
          <Logo />

          <NavigationMenu className="hidden sm:block">
            <NavigationMenuList className="space-x-0 md:space-x-1">
              <NavigationMenuItem>
                <NavigationMenuTrigger>Услуги</NavigationMenuTrigger>

                <NavigationMenuContent>
                  <ul className="gap-3 p-4 sm:w-[300px] lg:w-[400px]">
                    <li>
                      <Link
                        className={cn({
                          'text-brand-accent-dark dark:text-brand-accent-normal': pathname === '/individual-lessons',
                        })}
                        href="/individual-lessons"
                        aria-label="Перейти на страницу Индивидуальные занятия"
                      >
                        <div className="group rounded-md p-2 hover:bg-secondary">
                          <div className="mb-1 flex items-center">
                            <IndividualLessonsIcon className="mr-2 h-5 w-5" />
                            <span className="text-sm group-hover:underline group-hover:decoration-brand-accent-normal">
                              Индивидуальные занятия
                            </span>
                          </div>

                          <p className="text-xss text-muted-foreground">
                            Для тех, кто нацелен на&nbsp;быстрый результат
                          </p>
                        </div>
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={cn({
                          'text-brand-accent-dark dark:text-brand-accent-normal': pathname === '/pair-lessons',
                        })}
                        href="/pair-lessons"
                        aria-label="Перейти на страницу Парные занятия"
                      >
                        <div className="group rounded-md p-2 hover:bg-secondary">
                          <div className="mb-1 flex items-center">
                            <PairLessonsIcon className="mr-2 h-5 w-5" />
                            <span className="text-sm group-hover:underline group-hover:decoration-brand-accent-normal">
                              Парные занятия
                            </span>
                          </div>

                          <p className="text-xss text-muted-foreground">
                            Работа в&nbsp;паре со&nbsp;специалистом из&nbsp;смежной или&nbsp;вашей сферы
                          </p>
                        </div>
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={cn({
                          'text-brand-accent-dark dark:text-brand-accent-normal': pathname === '/group-lessons',
                        })}
                        href="/group-lessons"
                        aria-label="Перейти на страницу Групповые занятия"
                      >
                        <div className="group rounded-md p-2 hover:bg-secondary">
                          <div className="mb-1 flex items-center">
                            <GroupLessonsIcon className="mr-2 h-5 w-5" />
                            <span className="text-sm group-hover:underline group-hover:decoration-brand-accent-normal">
                              Групповые занятия
                            </span>
                          </div>

                          <p className="text-xss text-muted-foreground">Работа в&nbsp;группе единомышленников</p>
                        </div>
                      </Link>
                    </li>

                    <li>
                      <Link
                        className={cn({
                          'text-brand-accent-dark dark:text-brand-accent-normal': pathname === '/speaking-clubs',
                        })}
                        href="/speaking-clubs"
                        aria-label="Перейти на страницу Разговорный клуб"
                      >
                        <div className="group rounded-md p-2 hover:bg-secondary">
                          <div className="mb-1 flex items-center">
                            <SpeakingClubIcon className="mr-2 h-5 w-5" />
                            <span className="text-sm group-hover:underline group-hover:decoration-brand-accent-normal">
                              Разговорный клуб
                            </span>
                          </div>

                          <p className="text-xss text-muted-foreground">Практика живого общения с&nbsp;коллегами</p>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>

              <NavigationMenuItem>
                <Link href="/for-companies" aria-label="Перейти на страницу Для компаний">
                  <Button
                    className={cn({
                      'text-brand-accent-dark dark:text-brand-accent-normal': pathname === '/for-companies',
                    })}
                    variant="link"
                    aria-label="Перейти на страницу Для компаний"
                  >
                    Для компаний
                  </Button>
                </Link>
              </NavigationMenuItem>

              <NavigationMenuItem>
                <Link href="/team" aria-label="Перейти на страницу Команда">
                  <Button
                    className={cn({ 'text-brand-accent-dark dark:text-brand-accent-normal': pathname === '/team' })}
                    variant="link"
                    aria-label="Перейти на страницу Команда"
                  >
                    Команда
                  </Button>
                </Link>
              </NavigationMenuItem>

              <NavigationMenuItem>
                <Link href="/cases" aria-label="Перейти на страницу Кейсы">
                  <Button
                    className={cn({ 'text-brand-accent-dark dark:text-brand-accent-normal': pathname === '/cases' })}
                    variant="link"
                    aria-label="Перейти на страницу Кейсы"
                  >
                    Кейсы
                  </Button>
                </Link>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>

          <div className="flex items-center gap-6">
            <Link href="/payment" aria-label="Перейти на страницу Оплата">
              <Button
                size="icon-lg"
                variant="secondary"
                className={cn({
                  'text-brand-accent-dark dark:text-brand-accent-normal': pathname === '/payment',
                })}
                aria-label="Перейти на страницу Оплата"
              >
                <span className="sr-only">Оплата</span>
                &#8381;
              </Button>
            </Link>

            <div className="hidden sm:block">
              <ThemeToggle />
            </div>

            <div className="block sm:hidden">
              <Sidebar />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
